import React from 'react';
import TopBar from './TopBar'; 

const Places = () => {
  return (
    <>
      <TopBar /> 
      <div className="places-container">
        <h1>Места</h1>
      </div>
    </>
  );
};

export default Places;
