import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import UserMenu from './UserMenu';
import TowTruck from './TowTruck';
import './TopBar.css'; 

const TopBar = () => {
  const navigate = useNavigate(); 

  const handleLogoClick = () => {
    navigate('/'); 
  };

  return (
      <div className='top-bar'>
       <UserMenu />
       <img
        src='/img/reg.svg' 
        alt='Logo'
        className='logo'
        onClick={handleLogoClick} 
      />
      <TowTruck />
      </div>
  );
};

export default TopBar;
