import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar'; // Импортируйте TopBar
import './Main.css';

const Main = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false); // State to track data loading
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isTokenValid, setIsTokenValid] = useState(null); // State for token validation status
  const articlesPerPage = 5;

  const observer = useRef();
  const lastArticleElementRef = useRef();

  const navigate = useNavigate();

  // Function to validate token
  const validateToken = async (token) => {
    try {
      const response = await fetch('/api/validate-token.php', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Неизвестная ошибка');
      }
      return data.success === 1;
    } catch (error) {
      console.error('Ошибка проверки токена:', error);
      return false;
    }
  };

  // Effect to check token and set validity
  useEffect(() => {
    const checkToken = async () => {
      const token = sessionStorage.getItem('token') || localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const isValid = await validateToken(token);
      if (!isValid) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userLocation');
        localStorage.removeItem('token');
        navigate('/login');
        return;
      }
      setIsTokenValid(true); // Set token as valid
    };

    checkToken();
  }, [navigate]);

  // Effect to fetch RSS data when token is validated
  useEffect(() => {
    if (isTokenValid === null) return; // Wait until token validation is done

    const fetchRSS = async (page) => {
      setLoadingData(true); // Start loading data
      try {
        const response = await fetch(`/api/rss.php?page=${page}&limit=${articlesPerPage}`);
        if (!response.ok) {
          throw new Error('Ошибка сети');
        }
        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }

        setArticles(prevArticles => [...prevArticles, ...data.articles]);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError('Ошибка при загрузке новостей');
        console.error('Error fetching RSS feed:', error);
      } finally {
        setLoadingData(false); // End loading data
      }
    };

    // Fetch news data only after token is validated
    if (isTokenValid) {
      fetchRSS(currentPage);
      setLoading(false); // End loading state
    }
  }, [isTokenValid, currentPage]); // Trigger fetch when token is validated or page changes

  // Effect to observe when to load more articles
  useEffect(() => {
    if (loadingData || loading) return; // Avoid observer setup while loading

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        setCurrentPage(prevPage => prevPage + 1);
      }
    });

    if (lastArticleElementRef.current) {
      observer.current.observe(lastArticleElementRef.current);
    }
  }, [loadingData, loading, currentPage, totalPages]); // Added loadingData to dependencies

  return (
    <>
      <TopBar /> {/* Используем TopBar здесь */}
      <div className="news-container">
        <Helmet>
          <title>Мой регион</title>
        </Helmet>
        <h1 className="news-header">Новости</h1>
        {loading && currentPage === 1 && <div className="loading">Загрузка...</div>}
        <ul className="news-list">
          {articles.map((article, index) => {
            const isLast = articles.length === index + 1;
            return (
              <li
                key={index}
                className="news-item"
                ref={isLast ? lastArticleElementRef : null}
              >
                <a href={article.link} target="_blank" rel="noopener noreferrer" className="news-title">
                  {article.title}
                </a>
                <p className="news-description">{article.description}</p>
                {article.enclosure && (
                  <img
                    src={article.enclosure}
                    alt={article.title}
                    className="news-image" />
                )}
                <p className={`news-source ${article.source.toLowerCase().replace(/\s+/g, '-')}`}>
                  Источник: {article.source}
                </p>
              </li>
            );
          })}
        </ul>
        {loadingData && currentPage > 1 && <div className="loading">Загрузка...</div>}
      </div>
    </>
  );
};

export default Main;
