import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import TopBar from './TopBar'; 
import './Settings.css'; 

const Settings = () => {
  const navigate = useNavigate(); 

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userLocation');
    localStorage.removeItem('token');
    navigate('/login'); 
  };

  return (
    <>
      <TopBar /> 
      <div className="settings-container">
        <h1>Настройки</h1>
        


        <button className="logout-button" onClick={handleLogout}>Выход</button>
      </div>
    </>
  );
};

export default Settings;
