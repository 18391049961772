import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { UserProvider } from './UserContext';
import Login from './Login';
import Registration from './Registration'; 
import Main from './Main';
import Messages from './Messages'; 
import Chat from './Chat';
import Followers from './Followers'; 
import Places from './Places'; 
import Fines from './Fines';
import Settings from './Settings'; 
import Profile from './Profile';
import Friends from './Friends';
import ChatInitializer from './ChatInitializer';


const App = () => {
  return (
    <HelmetProvider>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/followers" element={<Followers />} />
            <Route path="/places" element={<Places />} />
            <Route path="/fines" element={<Fines />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/friends" element={<Friends />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/chat/:chatId" element={<Chat />} />
            <Route path="/start-chat/:userId" element={<ChatInitializer />} />
            <Route path="/profile/:id" element={<Profile />} />
          </Routes>
        </Router>
      </UserProvider>
    </HelmetProvider>
  );
};

export default App;
