import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; 
import './Login.css';

const Login = () => {
  const navigate = useNavigate(); 
  const [form, setForm] = useState({
    phoneNumber: '',
    password: '',
    rememberMe: false
  });

  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    setForm({
      ...form,
      [id]: type === 'checkbox' ? checked : value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.phoneNumber) newErrors.phoneNumber = 'Номер телефона обязателен';
    if (!form.password) newErrors.password = 'Пароль обязателен';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch('/api/login.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(form)
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            const storage = form.rememberMe ? localStorage : sessionStorage;
            storage.setItem('token', data.token);
            console.log('Авторизация успешна', data);
            navigate('/');
          } else {
            setServerError(data.error || 'Неизвестная ошибка');
          }
        } else {
          const errorText = await response.text();
          setServerError(errorText || 'Ошибка при авторизации');
        }
      } catch (error) {
        console.error('Ошибка сети:', error);
        setServerError('Ошибка сети'); 
      }
    }
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    const validValue = value.replace(/[^0-9+]/g, '');
    setForm({
      ...form,
      phoneNumber: validValue
    });
  };

  return (
    <div className="container">
      <Helmet>
        <title>Вход</title>
      </Helmet>
      <h2 className="text-center">Мой регион</h2>
      <form onSubmit={handleSubmit}>
        {serverError && <div className="error">{serverError}</div>}
        <div className="form-group">
          <input
            type="text"
            id="phoneNumber"
            className={`input-form ${errors.phoneNumber ? 'error' : ''}`}
            placeholder="Номер телефона"
            value={form.phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
        </div>

        <div className="form-group"> 
          <Input.Password // тут изменен input на input из atnd и добавлен iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            type="password"
            id="password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            className={`input-form ${errors.password ? 'error' : ''}`}
            placeholder="Пароль" 
            value={form.password}
            onChange={handleChange}
          />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>

        <div className="text-right">
          <a href="/forgot-password" className="forgot-password">Забыли пароль?</a>
        </div>

        <div className="form-group form-check">
          <input
            type="checkbox"
            id="rememberMe"
            className="form-check-input"
            checked={form.rememberMe}
            onChange={handleChange}
          />
          <label htmlFor="rememberMe" className="form-check-label">Запомнить меня</label>
        </div>

        <button type="submit" className="btn btn-primary btn-block">Вход</button>

        <div className="text-center mt-3">
          <span className="text-muted">Еще нет аккаунта? </span>
          <a href="/registration" className="register-link">Зарегистрироваться</a>
        </div>
      </form>
    </div>
  );
}

export default Login;
