import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapComponent.css';

const MapComponent = ({ position }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      if (!mapRef.current) {
        mapboxgl.accessToken = 'pk.eyJ1IjoiZGFuZGFtYWV2IiwiYSI6ImNtMG5sOG1zYTA0N2oycXNkZDNnbGs4NzkifQ.oLjF5pm6mDyJHcWDplD5Zg';

        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: position.latitude && position.longitude
            ? [position.longitude, position.latitude]
            : [30.3141, 59.9386], // начальная позиция, если нет данных о местоположении
          zoom: position.latitude && position.longitude ? 16 : 9
        });

        mapRef.current.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
          })
        );
      }

      // Обновление центра карты
      if (position.latitude && position.longitude) {
        mapRef.current.setCenter([position.longitude, position.latitude]);
      }
    }
  }, [position]);

  return <div className="map-container" style={{  }} ref={mapContainerRef} />;
};

export default MapComponent;
