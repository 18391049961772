import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Avatar, message, Skeleton } from 'antd';
import { UserOutlined, SendOutlined, CheckOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { TextArea, List, NavBar } from 'antd-mobile';
import './Chat.css';


// Заменить отображение skeleton
// Изменить цвета сообщений отойти от цветов what's app
// 
const Chat = () => {
  const { chatId } = useParams();
  const currentUser = useUser();
  const [messages, setMessages] = useState([]);
  const [chatPartner, setChatPartner] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isUserLoaded, setIsUserLoaded] = useState(false); // Состояние для проверки загрузки данных пользователя
  const pollingIdRef = useRef(null);
  const navigate = useNavigate();

  const getToken = () => localStorage.getItem('token') || sessionStorage.getItem('token');

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${getToken()}`,
  });

  const fetchMessages = async () => {
    if (!currentUser?.id) return; // Не загружать сообщения, если пользователь еще не загружен

    setLoading(true);
    try {
      const response = await axios.get(`/api/getMessages.php?chat_id=${chatId}`, {
        headers: getAuthHeaders(),
      });
      if (response.data) {
        setMessages(response.data.messages || []);
        setChatPartner(response.data.chat_partner || null);

        // Отправляем запрос на сервер для обновления статуса прочтения
        await axios.post('/api/updateMessageStatus.php', {
          chat_id: chatId,
          user_id: currentUser.id,
        }, {
          headers: getAuthHeaders(),
        });
      } else {
        setMessages([]);
        setChatPartner(null);
      }
    } catch (error) {
      console.error('Ошибка при загрузке сообщений:', error);
      message.error('Ошибка при загрузке сообщений');
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !currentUser?.id) return;
    try {
      const response = await axios.post('/api/sendMessage.php', {
        chat_id: chatId,
        sender_id: currentUser.id,
        content: newMessage,
      }, {
        headers: getAuthHeaders(),
      });

      if (response.data && response.data.success === true) {
        const newMessageData = {
          id: response.data.message_id, // предполагается, что сервер возвращает ID сообщения
          content: newMessage,
          sender_id: currentUser.id,
          created_at: new Date().toISOString(),
          is_read: 'f', // новое сообщение по умолчанию не прочитано
        };

        // Добавляем новое сообщение в состояние сразу после отправки
        setMessages(prevMessages => [
          ...prevMessages,
          newMessageData,
        ]);
        setNewMessage('');
      } else {
        console.error('Не удалось отправить сообщение, success не true:', response.data);
        message.error('Не удалось отправить сообщение');
      }
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
      message.error('Ошибка при отправке сообщения');
    }
  };

  const updateMessageStatus = async () => {
    try {
      await axios.post('/api/updateMessageStatus.php', {
        chat_id: chatId,
        user_id: currentUser.id,
      }, {
        headers: getAuthHeaders(),
      });
    } catch (error) {
      console.error('Ошибка при обновлении статуса сообщений:', error);
      message.error('Ошибка при обновлении статуса сообщений');
    }
  };

  const longPolling = async () => {
    if (!currentUser?.id) return; // Не выполнять long polling, если пользователь еще не загружен

    try {
      const lastMessageId = messages.length > 0 ? messages[messages.length - 1].id : 0;
      const response = await axios.get(`/api/getMessages.php?chat_id=${chatId}&poll=true&last_message_id=${lastMessageId}`, {
        headers: getAuthHeaders(),
      });

      if (response.data && response.data.messages) {
        const newMessages = response.data.messages;

        // Обновляем существующие сообщения и добавляем новые
        setMessages(prevMessages => [
          ...prevMessages.map(msg => {
            const updatedMsg = newMessages.find(m => m.id === msg.id);
            return updatedMsg ? { ...msg, is_read: updatedMsg.is_read } : msg;
          }),
          ...newMessages.filter(newMsg => !prevMessages.some(msg => msg.id === newMsg.id))
        ]);

        // Обновляем статус прочтения для новых сообщений
        const unreadMessageIds = newMessages
          .filter(msg => msg.sender_id !== currentUser.id && msg.is_read === 'f')
          .map(msg => msg.id);
        
        if (unreadMessageIds.length > 0) {
          await updateMessageStatus(unreadMessageIds);
        }
      }
    } catch (error) {
      console.error('Ошибка при long polling:', error);
      message.error('Ошибка при long polling');
    } finally {
      pollingIdRef.current = setTimeout(longPolling, 2500);
    }
  };

  useEffect(() => {
    if (currentUser?.id) {
      setIsUserLoaded(true); // Устанавливаем флаг после получения данных пользователя
      fetchMessages();
      longPolling();
    } else {
      setIsUserLoaded(false); // Устанавливаем флаг в false, если данные пользователя еще не загружены
    }

    return () => {
      if (pollingIdRef.current) {
        clearTimeout(pollingIdRef.current);
      }
    };
  }, [chatId, currentUser]);

  if (!isUserLoaded) {
    // Если данные пользователя еще не загружены, показываем индикатор загрузки
    return <Skeleton active />;
  }

  return (
    <div className='chat-container'>
      <Skeleton loading={loading} active avatar>
        {chatPartner && (
          <NavBar onBack={() => navigate(-1)} style={{ '--height': 'auto', padding: '5px 0' }}>
            <div onClick={() => navigate(`/profile/${chatPartner.id}`)}>
              <Avatar
                size={40}
                src={chatPartner?.avatarurl}
                icon={!chatPartner?.avatarurl && <UserOutlined />}
              />
              <span style={{ marginLeft: '10px' }}>{chatPartner.full_name}</span>
            </div>
          </NavBar>
        )}
      </Skeleton>

      <div className='chat-messages'>
        <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
          {messages.length === 0 ? (
            <p>В этом чате пока нет сообщений.</p>
          ) : (
            <List className='chat-list' style={{ '--border-inner': 'none', '--border-top': 'none', '--border-bottom': 'none' }}>
              {messages.map((item) => (
                item?.id ? (
                  <List.Item
                    key={item.id}
                    className={item.sender_id === currentUser?.id ? 'message-right' : 'message-left'}
                  >
                    <div className='message-content'>
                      <div className='message-text'>
                        {item?.content}
                        <div className='message-time'>
                          {new Date(item.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          {/* Отображение статуса сообщения */}
                          {item.sender_id === currentUser.id && (
                            item.is_read === 't' ? ( // Проверка на 't' для прочитанного сообщения
                              <>
                                <CheckOutlined style={{ marginLeft: 5 }} />
                                <CheckOutlined style={{ marginLeft: -8 }} />
                              </>
                            ) : (
                              <CheckOutlined style={{ marginLeft: 5 }} />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </List.Item>
                ) : null // Проверка на наличие id, чтобы избежать ошибок
              ))}
            </List>
          )}
        </Skeleton>
      </div>

      <div className='message-input'>
        <TextArea
          value={newMessage}
          onChange={(value) => setNewMessage(value)}
          placeholder='Введите ваше сообщение'
          autoSize={{ minRows: 1, maxRows: 5 }}
          rows={1}
          className='textarea'
        />
        <Button
          onClick={handleSendMessage}
          type='primary'
          icon={<SendOutlined />}
        />
      </div>
    </div>
  );
};

export default Chat;
