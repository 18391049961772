import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Avatar } from 'antd';
import { List, SearchBar } from 'antd-mobile';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
import TopBar from './TopBar';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import './Friends.css';

const Friends = () => {
  const [friends, setFriends] = useState([]);
  const currentUser = useUser(); // Используйте хук контекста
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasFriends, setHasFriends] = useState(false);
  const navigate = useNavigate();
  
  const handleFocus = (e) => {
    e.preventDefault(); // Предотвращаем поведение по умолчанию
    e.stopPropagation();
  }; 

  const handleNavigation = (id) => {
    navigate(`/profile/${id}`); // Переход на страницу профиля с соответствующим id
  };
  
  useEffect(() => {
    const fetchFriends = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const response = await axios.get('/api/friends.php', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        const friendsList = response.data.friends || [];
        setFriends(friendsList);
        setHasFriends(friendsList.length > 0);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFriends();
  }, []);

  useEffect(() => {
    const searchFriends = async () => {
      if (searchQuery.trim() === '') {
        setSearchResults([]);
        return;
      }

      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const response = await axios.post('/api/search.php', 
          { 
            query: searchQuery, 
            id_user: currentUser?.id // Добавляем id_user в тело запроса
          }, 
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            }
          }
        );
        setSearchResults(response.data.results || []);
      } catch (err) {
        setError(err.message);
      }
    };

    const debounceSearch = setTimeout(searchFriends, 300);

    return () => clearTimeout(debounceSearch);
  }, [searchQuery, currentUser]);

  const showFriendsList = !searchQuery.trim();

  return (
    <>
      <TopBar />
      <div className="friends-container">
        <div className='searcher-container'>
          <SearchBar
            placeholder="Поиск друзей..."
            value={String(searchQuery)}
            onChange={(value) => setSearchQuery(value)}
            onFocus={handleFocus}
            style={{ '--border-radius': '10px', width: '100%', '--background': '#ffffff', '--height' : '40px' }} 
            />
        </div>
        {loading && <p>Загрузка...</p>}
        {error && <p>Ошибка: {error}</p>}
        {!loading && !hasFriends && <p>Пока что друзей нет</p>}
        {!loading && showFriendsList && hasFriends && (
          <div>
            <List>
              {friends.map((friend) => (
                <List.Item
                  key={friend.id}
                  prefix={<Avatar size={40} src={friend?.avatarurl} icon={!friend?.avatarUrl && <UserOutlined />} />}
                  onClick={() => handleNavigation(friend.id)} // Переход при клике
                  arrowIcon={false}
                >
                  {friend.name} {friend.surname}
                </List.Item>
              ))}
            </List>
          </div>
        )}
        {!loading && searchQuery.trim() && (
          <div>
            <h3 className='search-result'>Результаты поиска</h3>
            <List>
              {searchResults.length > 0 ? (
                searchResults.map((result) => (
                  <List.Item
                    key={result.id}
                    prefix={<Avatar size={40} src={result?.avatarurl} icon={!result?.avatarUrl && <UserOutlined />} />}
                    onClick={() => handleNavigation(result.id)} // Переход при клике
                    arrowIcon={false}
                  >
                    {result.name} {result.surname}
                  </List.Item>
                ))
              ) : (
                <List.Item>Ничего не найдено</List.Item>
              )}
            </List>
          </div>
        )}
      </div>
    </>
  );
};

export default Friends;
