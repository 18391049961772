// TowTruck.js
import React, { useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';
import useGeolocation from './useGeolocation';
import MapComponent from './MapComponent';
import './TowTruck.css';
import mapboxgl from 'mapbox-gl';

const TowTruck = () => {
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState('');
  const { position, error: geoError } = useGeolocation();
  const navigate = useNavigate();

  // Функция для получения адреса через Mapbox Geocoding API
  const fetchAddress = async (latitude, longitude) => {
    const accessToken = 'pk.eyJ1IjoiZGFuZGFtYWV2IiwiYSI6ImNtMG5sOG1zYTA0N2oycXNkZDNnbGs4NzkifQ.oLjF5pm6mDyJHcWDplD5Zg';
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}&limit=1`;

    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.features && data.features.length > 0) {
        const feature = data.features[0];
        // Используем place_name для отображения полного адреса
        const fullAddress = feature.place_name || 'Адрес не найден';
        setAddress(fullAddress);
      } else {
        setAddress('Адрес не найден');
      }
    } catch (error) {
      console.error('Ошибка при определении адреса:', error);
      setAddress('Ошибка при определении адреса');
    }
  };
  
  
  useEffect(() => {
    if (geoError) {
      console.error('Ошибка доступа к геолокации:', geoError);
    }
  }, [geoError]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  useEffect(() => {
    // Когда координаты пользователя доступны, получаем адрес
    if (position.latitude && position.longitude) {
      fetchAddress(position.latitude, position.longitude);
    }
  }, [position]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <Button type="text" className='btn-truck' onClick={showDrawer} style={{ padding: 0 }}>
        <img src='/img/tow-truck.svg' alt='Tow Truck' className='truck-icon' />
      </Button>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        className="custom-drawer"
        bodyStyle={{ padding: 0 }}
      >
        <div className="tow-truck-menu">
          <div className="tow-truck-info">
            <h3>Информация об эвакуаторе</h3>
          </div>
          <MapComponent position={position} />
          <div className="address-display">
            <p>{address}</p> {/* Отображение адреса */}
          </div>
          <div className="tow-truck-actions">
            <Button className='btn-truck-alert'>Сообщить об эвакуаторе</Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default TowTruck;
