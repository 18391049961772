import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {  Avatar, message, Typography  } from 'antd';
import { List, SearchBar } from 'antd-mobile';
import { UserOutlined } from '@ant-design/icons';
import { useUser } from './UserContext';
import TopBar from './TopBar';
import './Messages.css';

const { Text } = Typography;


const Messages = () => {
  const currentUser = useUser(); // Получаем текущего пользователя из контекста
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // Состояние для поискового запроса
  const navigate = useNavigate(); // Хук для навигации
  const pollingIdRef = useRef(null); // Используем useRef для хранения ID таймера
  const isMounted = useRef(true); // Флаг для проверки монтирования компонента


  useEffect(() => {
    isMounted.current = true; // Компонент смонтирован
    const fetchChats = async () => {
      if (!currentUser) return;

      setLoading(true);
      try {
        const response = await axios.get('/api/chats.php', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
          },
        });

        if (Array.isArray(response.data)) {
          setChats(response.data);
        } else {
          throw new Error('Неверный формат данных');
        }
      } catch (err) {
        setError(err.response ? err.response.data.error : 'Ошибка при загрузке чатов');
        message.error(err.response ? err.response.data.error : 'Ошибка при загрузке чатов');
      } finally {
        setLoading(false);
      }
    };

    const longPolling = async () => {
      try {
        const response = await axios.get('/api/chats.php', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
          },
        });

        if (Array.isArray(response.data)) {
          if (isMounted.current) { // Проверяем, монтирован ли компонент
            setChats(response.data);
          }
        } else {
          throw new Error('Неверный формат данных');
        }
      } catch (err) {
        if (isMounted.current) { // Проверяем, монтирован ли компонент
          console.error('Ошибка при long polling:', err);
          message.error('Ошибка при long polling');
        }
      } finally {
        if (isMounted.current) { // Проверяем, монтирован ли компонент
          // Сначала очистим предыдущий таймер, если он есть
          if (pollingIdRef.current) {
            clearTimeout(pollingIdRef.current);
          }
          // Установим новый таймер и сохраним его ID в useRef
          pollingIdRef.current = setTimeout(longPolling, 2500); // Повторять каждые 2,5 секунд
        }
      }
    };

    fetchChats(); // Первоначальная загрузка чатов
    longPolling(); // Запуск long polling

    // Очистка при размонтировании компонента
    return () => {
      isMounted.current = false; // Компонент размонтирован
      if (pollingIdRef.current) {
        clearTimeout(pollingIdRef.current); // Очищаем таймер
      }
    };
  }, [currentUser]);

  const handleChatClick = (chatId) => {
    navigate(`/chat/${chatId}`); // Переход на страницу чата
  };

  // Фильтрация чатов на основе поискового запроса
  const filteredChats = chats.filter(chat => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const chatName = chat.chat_type === 'private' ? chat.full_name : chat.chat_name;
    return chatName.toLowerCase().includes(lowerCaseSearchTerm);
  });

  return (
    <>
      <TopBar />
      <div className="messages-container">
        <div className='searcher-container'>
          <SearchBar
            placeholder="Поиск чатов..."
            value={String(searchTerm)} // Преобразуем searchTerm в строку
            onChange={(value) => setSearchTerm(value)} // value всегда будет строкой
            style={{ '--border-radius': '10px', width: '100%', '--background': '#ffffff', '--height' : '40px' }} 
          />
        </div>
        {loading && <p>Загрузка чатов...</p>}
        {error && <p>Ошибка: {error}</p>}
        <div className="messages-list">
          <List>
            {filteredChats.map(chat => (
              <List.Item
                key={chat.id}
                onClick={() => handleChatClick(chat.id)}
                prefix={<Avatar src={chat.avatarurl} icon={!chat?.avatarUrl && <UserOutlined />} size={40} />}
                arrowIcon={false}
                description={
                  <div className="text-container">
                    <Text
                      className="text-message"
                      ellipsis
                    >
                      {chat.last_message || 'Нет сообщений'}
                    </Text>
                  </div>
                }
              >
                {chat.chat_type === 'private' ? chat.full_name : chat.chat_name}
              </List.Item>
            ))}
          </List>
        </div>
      </div>
    </>
  );
};

export default Messages;
