import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useUser } from './UserContext';
import { message } from 'antd';

const ChatInitializer = () => {
  const { userId } = useParams(); // ID пользователя, с которым инициализируется чат
  const navigate = useNavigate();
  const currentUser = useUser();

  useEffect(() => {
    const initializeChat = async () => {
      try {
        // Проверяем, существует ли чат между текущим пользователем и userId
        const response = await axios.get(`/api/getChatId.php?user1_id=${currentUser.id}&user2_id=${userId}`);
        const chatData = response.data;

        if (chatData.chatId) {
          // Если чат существует, перенаправляем на него
          navigate(`/chat/${chatData.chatId}`);
        } else {
          // Если чата нет, создаем новый и перенаправляем
          const createChatResponse = await axios.post('/api/createChat.php', {
            user1_id: currentUser.id,
            user2_id: userId,
            chatType: 'private',
          });
          navigate(`/chat/${createChatResponse.data.chat_id}`);
        }
      } catch (error) {
        console.error('Ошибка при инициализации чата:', error);
        message.error('Ошибка при инициализации чата');
      }
    };

    initializeChat();
  }, [userId, currentUser.id, navigate]);

  return <div>Инициализация чата...</div>; // Можно отобразить лоадер или что-то подобное
};

export default ChatInitializer;
