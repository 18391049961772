import React from 'react';
import TopBar from './TopBar'; 

const Fines = () => {
  return (
    <>
      <TopBar /> 
      <div className="fines-container">
        <h1>Штрафы</h1>
        
      </div>
    </>
  );
};

export default Fines;
