import React from 'react';
import TopBar from './TopBar'; 

const Followers = () => {
  return (
    <>
      <TopBar /> 
      <div className="followers-container">
        <h1>Попутчики</h1>
        
      </div>
    </>
  );
};

export default Followers;
