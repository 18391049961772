import { useState, useEffect } from 'react';

const useGeolocation = () => {
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleSuccess = (event) => {
      const { latitude, longitude } = event.coords;
      setPosition({ latitude, longitude });
    };

    const handleError = (event) => {
      let errorMessage;
      switch (event.code) {
        case event.PERMISSION_DENIED:
          errorMessage = 'Доступ к геолокации запрещен. Пожалуйста, разрешите доступ в настройках вашего устройства.';
          break;
        case event.POSITION_UNAVAILABLE:
          errorMessage = 'Информация о местоположении недоступна.';
          break;
        case event.TIMEOUT:
          errorMessage = 'Время ожидания геолокации истекло.';
          break;
        default:
          errorMessage = 'Произошла неизвестная ошибка при получении геолокации.';
          break;
      }
      setError(errorMessage);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      });
    } else {
      setError('Геолокация не поддерживается вашим браузером');
    }
  }, []);

  return { position, error };
};

export default useGeolocation;
