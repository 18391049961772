import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import axios from 'axios';
import { Avatar, Button, message, Upload, Drawer, Typography } from 'antd';
import { UploadOutlined, MessageOutlined, EditOutlined, UserOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { useUser } from './UserContext'; // Импорт хука контекста
import { Dialog, TextArea } from 'antd-mobile'; // Импорт Dialog из antd-mobile
import './Profile.css';

const { Paragraph } = Typography;

const Profile = () => {
  // Получаем ID пользователя из параметров URL
  const { id } = useParams();
  const navigate = useNavigate();

  // Состояния компонента
  const [userData, setUserData] = useState(null);
  const [editMood, setEditMood] = useState(false);
  const [newMood, setNewMood] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [friendStatus, setFriendStatus] = useState({
    status: null,
    initiator: null,
    responder: null,
  });
  const [isExpanded, setIsExpanded] = useState(false);

  const currentUser = useUser(); // Получаем текущего пользователя

  // Функция для получения токена из localStorage или sessionStorage
  const getToken = () => localStorage.getItem('token') || sessionStorage.getItem('token');

  // Хук для загрузки данных пользователя при монтировании компонента
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        // Запрос для получения данных пользователя
        const userResponse = await axios.get(`/api/userProfile.php?id=${id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        setUserData(userResponse.data);

        // Запрос для получения статуса дружбы
        const friendStatusResponse = await axios.get(`/api/friendStatus.php?id=${id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        setFriendStatus(friendStatusResponse.data);
      } catch (err) {
        setError(err.response ? err.response.data.error : 'Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  // Обработчик изменения настроения
  const handleMoodChange = () => {
    setNewMood(userData.mood || '');
    setDrawerVisible(true);
  };

  // Обработчик сохранения настроения
  const handleMoodSave = async () => {
    try {
      await axios.post('/api/updateMood.php', { mood: newMood }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      setUserData((prev) => ({ ...prev, mood: newMood }));
      setDrawerVisible(false);
      message.success('Настроение обновлено');
    } catch (error) {
      console.error(error);
      message.error('Ошибка при обновлении настроения');
    }
  };

  // Обработчик отмены изменения настроения
  const handleMoodCancel = () => {
    setDrawerVisible(false);
    setNewMood(userData.mood || '');
  };

  // Обработчик изменения аватара
  const handleUploadChange = (info) => {
    if (info.file.status === 'done') {
      message.success('Аватар обновлен');
      setUserData((prev) => ({ ...prev, avatarUrl: info.file.response.avatarUrl }));
    } else if (info.file.status === 'error') {
      message.error('Ошибка при загрузке аватара');
    }
  };

  // Обработчик клика по аватару
  const handleAvatarClick = () => {
    setPreviewVisible(true);
  };

  // Обработчик закрытия предпросмотра аватара
  const handlePreviewClose = () => {
    setPreviewVisible(false);
  };

  // Обработчик добавления в друзья
  const handleAddFriend = async () => {
    try {
      await axios.post('/api/addFriend.php', { friendId: id }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setFriendStatus({
        status: 'pending',
        initiator: currentUser.id,
        responder: id,
      });
      message.success('Запрос отправлен');
    } catch (error) {
      console.error('Ошибка при добавлении в друзья:', error);
      message.error('Ошибка при добавлении в друзья');
    }
  };

  // Обработчик принятия запроса в друзья
  const handleAcceptFriendRequest = async () => {
    try {
      await axios.post('/api/addFriend.php', { friendId: id }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setFriendStatus((prevStatus) => ({
        ...prevStatus,
        status: 'accepted',
      }));
      message.success('Запрос принят');
    } catch (error) {
      console.error('Ошибка при принятии запроса:', error);
      message.error('Ошибка при принятии запроса');
    }
  };

  // Обработчик удаления друга
  const handleRemoveFriend = async () => {
    try {
      await axios.post('/api/removeFriend.php', { friendId: id }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setFriendStatus((prevStatus) => ({
        ...prevStatus,
        status: null,
      }));
      message.success('Друг удален');
    } catch (error) {
      console.error('Ошибка при удалении друга:', error);
      message.error('Ошибка при удалении друга');
    }
  };

  // Подтверждение удаления друга
  const confirmRemoveFriend = () => {
    Dialog.confirm({
      title: 'Подтвердите удаление',
      content: `Вы хотите удалить ${userData.name} из друзей?`,
      confirmText: 'Да',
      cancelText: 'Нет',
      onConfirm: handleRemoveFriend,
      onCancel: () => { },
    });
  };

  // Возвращает кнопки действий с друзьями в зависимости от статуса дружбы
  const getFriendButtons = () => {
    const buttonStyle = { marginRight: 10 };

    if (friendStatus.status === 'accepted') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button onClick={handleSendMessage} icon={<MessageOutlined />} style={buttonStyle}>
            Написать
          </Button>
          <Button onClick={confirmRemoveFriend} icon={<UserDeleteOutlined />} danger>
            Удалить из друзей
          </Button>
        </div>
      );
    } else if (friendStatus.status === 'pending') {
      return currentUser?.id === friendStatus.initiator ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button disabled icon={<UserOutlined />}>
            Запрос отправлен
          </Button>
          <Button onClick={handleSendMessage} icon={<MessageOutlined />} style={{ marginLeft: 10 }}>
            Написать
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button onClick={handleAcceptFriendRequest} icon={<UserAddOutlined />} style={buttonStyle}>
            Принять в друзья
          </Button>
          <Button onClick={handleSendMessage} icon={<MessageOutlined />}>
            Написать
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button onClick={handleAddFriend} icon={<UserAddOutlined />} style={buttonStyle}>
            Добавить в друзья
          </Button>
          <Button onClick={handleSendMessage} icon={<MessageOutlined />}>
            Написать
          </Button>
        </div>
      );
    }
  };

  // Обработчик отправки сообщения
  const handleSendMessage = async () => {
    try {
      // Получаем ID чата между текущим пользователем и этим пользователем
      const chatResponse = await axios.get(`/api/getChatId.php?user1_id=${currentUser.id}&user2_id=${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const chatData = chatResponse.data;

      if (chatData.chatId) {
        // Если чат существует, перенаправляем на него
        navigate(`/chat/${chatData.chatId}`);
      } else {
        // Если чата нет, создаем новый и перенаправляем
        const createChatResponse = await axios.post('/api/createChat.php', {
          user1_id: currentUser.id,
          user2_id: id,
          chatType: 'private',
        }, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        const newChat = createChatResponse.data;
        navigate(`/chat/${newChat.chatId}`);
      }
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
      message.error('Ошибка при отправке сообщения');
    }
  };

  // Отображает текст настроения с возможностью раскрытия
  const renderMoodText = () => {
    const moodText = userData.mood || (currentUser?.id === id
      ? 'У вас нет настроения'
      : `У ${userData.name.split(' ')[0]} нет настроения.`);
    const isTextLong = moodText.length > 100;

    return (
      <div className="mood-text-container">
        <div className='mood-container'>
          <Paragraph
            className="mood-text"
            ellipsis={
              isTextLong && !isExpanded
                ? { rows: 2, expandable: true, symbol: '' }
                : false
            }
            style={{ margin: 0 }}
          >
            {moodText}
          </Paragraph>
          {currentUser?.id === id && (
            <Button onClick={handleMoodChange} icon={<EditOutlined />} style={{ padding: '5px' }}></Button>
          )}
        </div>

        {isTextLong && (
          <div className="mood-actions">
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              type="link"
              className="mood-toggle-button"
            >
              {isExpanded ? 'Скрыть' : 'Далее'}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <TopBar />
      <div className="profile-container">
        {loading && <p>Загрузка данных...</p>}
        {error && <p>Ошибка: {error}</p>}
        {userData && (
          <div className="profile-info">
            
            <div className="avatar-section">
              <div className='avatar-container'>
                <Avatar
                  size={120}
                  src={userData?.avatarUrl}
                  icon={!userData?.avatarUrl && <UserOutlined />}
                  onClick={handleAvatarClick}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {currentUser?.id === id && (
                <Upload
                  name="avatar"
                  action="/api/uploadAvatar.php"
                  headers={{
                    Authorization: `Bearer ${getToken()}`,
                  }}
                  showUploadList={false}
                  onChange={handleUploadChange}
                  beforeUpload={(file) => {
                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                    if (!isJpgOrPng) {
                      message.error('Вы можете загружать только JPG/PNG файл');
                    }
                    return isJpgOrPng;
                  }}
                >
                  <Button icon={<UploadOutlined />} style={{ marginTop: 10 }}>
                    Изменить фото
                  </Button>
                </Upload>
              )}
            </div>
            <div className="profile-background">
              <div className="profile-details">
                <h2>{userData.name}</h2>
                <div className="user-car">
                  {userData.car && userData.licensePlate
                    ? `${userData.car}, ${userData.licensePlate}`
                    : userData.car || userData.licensePlate}
                </div>
              </div>
              <div>
                {renderMoodText()}
                {currentUser?.id !== id && getFriendButtons()}
              </div>
            </div>
          </div>
        )}
        <Drawer
          title="Измените ваше настроение"
          placement="bottom"
          closable={false}
          onClose={handleMoodCancel}
          visible={drawerVisible}
          bodyStyle={{ padding: '20px' }}
        >
          <TextArea
            value={newMood}
            onChange={(value) => setNewMood(value)}
            autoSize={{ minRows: 2, maxRows: 4 }}
            showCount
            maxLength={255}
            placeholder="Введите ваше настроение"
          />
          <div style={{ marginTop: '16px' }}>
            <Button type="primary" onClick={handleMoodSave} style={{ marginRight: '8px' }}>
              Сохранить
            </Button>
            <Button onClick={handleMoodCancel}>
              Отменить
            </Button>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default Profile;
